import { ContactReason, ContactUsBlockProps } from "@models/Contact Us/ContactUs.models";

export const mapToContactUsBlock = (data: any) : ContactUsBlockProps => {
    return {
        EmailAddress: data.email_address.value,
        PhoneNumber: data.phone_number.value,
        PhysicalAddress: data.physical_address.value,
        PostalAddress: data.postal_address.value,
        apiUrl: '',
        ContactReasons: mapToContactUsReasons(data),
        SendUsMessageTopBanner: data.send_us_a_message_top_banner.value,
        salesforceWebToCaseLink: "",
        salesforceWebToCaseOrgid:"",
        salesforceWebToCaseRetUrl: "",
        salesforceWebToCaseSubtypeName:"",
        reCaptchaSiteKeyV2:"",
        reCaptchaSiteKey:""
    }
}

const mapToContactUsReasons = (data: any) : ContactReason[] => {
    return data.reasons_for_contact_options.linked_items.map((contactReason: any) => {
        return {
            Reason: contactReason.elements.reason.value,
            Subject: contactReason.elements.email_subject.value,
            OrderNumberRequired: contactReason.elements.order_number_combination_required.value.length > 0 ? true : false,
            SubType: mapToContactUsSubType(contactReason.elements.subtype)
        }
    })
}

const mapToContactUsSubType = (data: any) : any[] => {
    return data.linked_items.map((element:any) =>{
        return element.elements.title.value
    })
}