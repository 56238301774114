import React from 'react'
import { Grid, Form } from 'semantic-ui-react'
import {KpButton} from "@elements/index"
import { H3, Paragraph1 } from '@styles/Global.styles';

import {
    ContactUsLabel,
    ContactUsLink,
    ContactUsIconContainer,
    ContactUsColumn,
    ContactUsLabelDark,
    ContactUsFormContainer,
    ContactUsColumnSeperator,
    ContactUsFormColumn,
    ContactUsAddress,
    ContactUsHeading
} from './ContactUsBlock.styles';


import {Formik}  from 'formik';
import * as Yup from 'yup';
import { KpFormDropdown, KpFormErrorMessageLeft, KpFormField, KpFormInputErrorMessage } from '@styles/Global.forms';
import theme from '@styles/theme';
import { EmailService } from '@services/index';
import { Phone, Email, Chat } from '@assets/svgs';
import { ContactReason, ContactUsBlockProps } from '@models/Contact Us/ContactUs.models';
import { htmlEncode } from '@utils/Helpers';
import { EmailRegex, NameRegex, PhoneRegex } from '@utils/Constant';
import Helmet from 'react-helmet';
import ReCaptcha from '@utils/reCaptcha';
import { ReCaptchaService } from '@services/ReCaptchaService';

interface ContactUsBlockState {
    selectedReason: ContactReason | undefined
    selectedSubtype: string | undefined
    reCaptchaError: string
}

/* eslint-disable jsx-a11y/label-has-associated-control */
class ContactUsBlock extends React.Component<ContactUsBlockProps,ContactUsBlockState>{
    constructor(props:ContactUsBlockProps){
        super(props);
        this.state = {
            selectedReason: this.props.ContactReasons && this.props.ContactReasons.length > 0 ? this.props.ContactReasons[0] : undefined ,
            selectedSubtype: this.props.ContactReasons && this.props.ContactReasons.length > 0 && this.props.ContactReasons[0].SubType.length > 0 ? (this.props.ContactReasons[0].SubType[0]) : undefined,
            reCaptchaError: ""
        }
    }

    intialStatus = {
        isSubmitting: false,
        isSubmitted: false
    }

    initialValues = {
        name: '',
        phone: '',
        email: '',
        message: '',
        reason: this.props.ContactReasons && this.props.ContactReasons.length > 0 ? this.props.ContactReasons[0].Reason : '',
        subtype: this.props.ContactReasons && this.props.ContactReasons.length > 0 && this.props.ContactReasons[0].SubType.length > 0 ? (this.props.ContactReasons[0].SubType[0]) : "",
        orderNumber: ''
    }

    submitForm = (values: any, actions: any, execute: () => Promise<string>, widgetV2: number | undefined) => {
        actions.setStatus({
            isSubmitting: true
        });

        const orderNumber = this.state.selectedReason && this.state.selectedReason.OrderNumberRequired ? values.orderNumber : '';

        if(this.state.selectedReason !== undefined){
            let recaptcha_v2: HTMLElement | null
            if(document){
              recaptcha_v2 = document.getElementById("g-recaptcha_contactus-v2")
            } else {
              return
            }

            if(recaptcha_v2){
                const token = window.grecaptcha.getResponse(widgetV2)
                ReCaptchaService.validateCaptcha(this.props.apiUrl, token, "v2").then(async (response: any) => {
                  if (response && response.data.Success)
                  {
                    EmailService.send(this.props.apiUrl,values.name, values.phone, `${this.state.selectedReason?.Subject} - ${orderNumber}` , htmlEncode(values.message), values.email).then((data:any) => {
                    }).then(() =>{
                        actions.setStatus({
                            isSubmitting: false,
                            isSubmitted: true
                        })
                    });

                  } else {
                    actions.setStatus({
                      isSubmitting: false,
                      isSubmitted: false
                    })
                    this.setState({reCaptchaError: "Please tick checkbox correctly"})
                    return false
                  }
                })
            }
        }
    }

    webToCase = (fields: any) => {
        var customHiddenIframeName='webToCase_api';
        if(!document.getElementById(customHiddenIframeName)){
            var theiFrame=document.createElement("iframe");
            theiFrame.id=customHiddenIframeName;
            theiFrame.name=customHiddenIframeName;
            theiFrame.src='about:blank';
            theiFrame.style.display='none';
            document.body.appendChild(theiFrame);
        }
        var form = document.createElement("form");
        form.method = "POST";
        form.action = this.props.salesforceWebToCaseLink;
        form.setAttribute("target", customHiddenIframeName);
        for (var fieldName in fields) {
            var theInput = document.createElement("input");
            theInput.name=fieldName;
            theInput.value=fields[fieldName];
            theInput.setAttribute("type", "hidden");
            form.appendChild(theInput);
        }
        document.body.appendChild(form);
        form.submit();
    }

    startLiveChat = () => {
        (window as Window & typeof globalThis).LC_API.open_chat_window();
    };

    render(){
        const {PhoneNumber, EmailAddress, PostalAddress, PhysicalAddress, ContactReasons, SendUsMessageTopBanner} = this.props;

        const validationSchema = Yup.object({
            name: Yup.string()
                .matches(NameRegex, "Enter valid characters")
                .required('Name is required'),
            phone: Yup.string()
                .matches(PhoneRegex, "Invalid phone number")
                .required('Phone is required'),
            email: Yup.string()
                .required('Email is required')
                .matches(EmailRegex ,'Must enter a valid email address'),
            reason: Yup.string()
                .required('Reason is required'),
            orderNumber: this.state.selectedReason && this.state.selectedReason.OrderNumberRequired ? Yup.string()
                .required('required').max(6, 'Must be less than 6 characters').matches(/^[a-zA-Z0-9]+$/, "Enter valid characters") : Yup.string(),
            message: Yup.string()
                .required('Message is required'),
        })

        return (
            <Grid>
                <Grid.Row>
                    <Helmet>
                        <script src={`https://www.google.com/recaptcha/api.js`}></script>
                    </Helmet>
                </Grid.Row>
                <Grid.Row>
                    <ContactUsColumnSeperator mobile={16} tablet={16} computer={6}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <ContactUsHeading>Contact Information</ContactUsHeading>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column mobile={16} tablet={16} computer={16}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column mobile={2} tablet={1} computer={1}>
                                                <ContactUsIconContainer>
                                                    <Phone width={30} height={30} fill={theme.brand.colors.green} />
                                                </ContactUsIconContainer>
                                            </Grid.Column>
                                            <ContactUsColumn mobile={14} tablet={7} computer={14} largeScreen={15}  widescreen={15}>
                                                <ContactUsLabel>PHONE</ContactUsLabel>
                                                <ContactUsLink href={`tel:${PhoneNumber}`} >{PhoneNumber}</ContactUsLink>
                                            </ContactUsColumn>
                                            {/* <Grid.Column mobile={2} tablet={1} computer={1}>
                                                <ContactUsIconContainer>
                                                    <Email height={45} width={30} fill={theme.brand.colors.green} />
                                                </ContactUsIconContainer>
                                            </Grid.Column>
                                            <ContactUsColumn mobile={14} tablet={7} computer={14}  largeScreen={15}  widescreen={15}>
                                                <ContactUsLabel>EMAIL</ContactUsLabel>
                                                <ContactUsLink href={`mailto:${EmailAddress}`} >{EmailAddress}</ContactUsLink>
                                            </ContactUsColumn> */}
                                            <Grid.Column mobile={2} tablet={1} computer={1}>
                                                <ContactUsIconContainer>
                                                    <Chat width={30} height={40} fill={theme.brand.colors.green} />
                                                </ContactUsIconContainer>
                                            </Grid.Column>
                                            <ContactUsColumn mobile={14} tablet={7} computer={14}  largeScreen={15}  widescreen={15}>
                                                <ContactUsLabel>LIVE CHAT</ContactUsLabel>
                                                <ContactUsLink onClick={this.startLiveChat}>Start a Live Chat</ContactUsLink>
                                            </ContactUsColumn>
                                            <Grid.Column width={1} only='computer'></Grid.Column>
                                            <Grid.Column mobile={14} tablet={8} computer={14}>
                                                <ContactUsLabelDark>Office hours: 8:30am to 5pm Monday to Friday (closed on weekends and public holidays).</ContactUsLabelDark>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={1} only='computer'></Grid.Column>
                                            <Grid.Column mobile={8} tablet={7} computer={7}  largeScreen={7}  widescreen={7}>
                                                    <ContactUsLabel>POSTAL ADDRESS</ContactUsLabel>
                                                    <ContactUsAddress dangerouslySetInnerHTML={{ __html: PostalAddress }}></ContactUsAddress>
                                            </Grid.Column>
                                            <Grid.Column width={1} only='tablet'></Grid.Column>
                                            <Grid.Column mobile={8} tablet={7} computer={7}  largeScreen={7}  widescreen={7}>
                                                    <ContactUsLabel>PHYSICAL ADDRESS</ContactUsLabel>
                                                    <ContactUsAddress dangerouslySetInnerHTML={{ __html: PhysicalAddress }}></ContactUsAddress>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </ContactUsColumnSeperator>
                    <Grid.Column mobile={16} tablet={16} computer={10}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={1} only='computer'></Grid.Column>
                                <ContactUsFormContainer mobile={16} tablet={15} computer={15}>

                                    <Grid>
                                        {SendUsMessageTopBanner.length > 0 &&  <Grid.Row>
                                            <Grid.Column width={16}>
                                                <div dangerouslySetInnerHTML={{ __html: SendUsMessageTopBanner }}></div>
                                            </Grid.Column>
                                        </Grid.Row>}
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <H3>Send us a Message</H3>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                            <ReCaptcha action="contactUs" reCaptchaSiteKey={this.props.reCaptchaSiteKey} reCaptchaSiteKeyV2={this.props.reCaptchaSiteKeyV2}>{captcha => (
                                                <Formik initialValues={this.initialValues} validationSchema={validationSchema} initialStatus={this.intialStatus} onSubmit={(values: any, actions: any) => this.submitForm(values, actions, captcha.execute, captcha.widgetV2)}>
                                                    { props => (
                                                        <Form onSubmit={props.handleSubmit}>
                                                            <Grid columns={16}>
                                                                <Grid.Row>
                                                                    {
                                                                        !props.status.isSubmitted && (
                                                                            <Grid.Column mobile={16} tablet={16} computer={14}>
                                                                                <Grid width={14}>
                                                                                    <ContactUsFormColumn mobile={16} tablet={8} computer={8}>
                                                                                        <Form.Group>
                                                                                            <KpFormField required error={props.errors.name && props.touched.name}>
                                                                                                <label>Name</label>
                                                                                                {
                                                                                                    props.errors.name && props.touched.name && (
                                                                                                        <KpFormInputErrorMessage>
                                                                                                            {props.errors.name}
                                                                                                        </KpFormInputErrorMessage>
                                                                                                    )
                                                                                                }
                                                                                                <input
                                                                                                    id='name'
                                                                                                    name='name'
                                                                                                    data-private="lipsum"
                                                                                                    type='text'
                                                                                                    aria-label='Name'
                                                                                                    disabled={props.status.isSubmitting}
                                                                                                    placeholder='Name'
                                                                                                    onChange={props.handleChange}
                                                                                                    onBlur={props.handleBlur}
                                                                                                    value={props.values.name}>
                                                                                                </input>
                                                                                            </KpFormField>
                                                                                        </Form.Group>
                                                                                    </ContactUsFormColumn>
                                                                                    <ContactUsFormColumn mobile={16} tablet={8} computer={8}>
                                                                                        <Form.Group>
                                                                                            <KpFormField required error={props.errors.email && props.touched.email}>
                                                                                                <label>Email</label>
                                                                                                {
                                                                                                    props.errors.email && props.touched.email && (
                                                                                                        <KpFormInputErrorMessage>
                                                                                                            {props.errors.email}
                                                                                                        </KpFormInputErrorMessage>
                                                                                                    )
                                                                                                }
                                                                                                <input
                                                                                                    id='email'
                                                                                                    name='email'
                                                                                                    data-private="lipsum"
                                                                                                    aria-label='Email Address'
                                                                                                    type='text'
                                                                                                    disabled={props.status.isSubmitting}
                                                                                                    placeholder='Email'
                                                                                                    onChange={props.handleChange}
                                                                                                    onBlur={props.handleBlur}
                                                                                                    value={props.values.email}>
                                                                                                </input>
                                                                                            </KpFormField>
                                                                                        </Form.Group>
                                                                                    </ContactUsFormColumn>
                                                                                    <ContactUsFormColumn mobile={16} tablet={8} computer={8}>
                                                                                        <Form.Group>
                                                                                            <KpFormField required error={props.errors.phone && props.touched.phone}>
                                                                                                <label>Phone</label>
                                                                                                {
                                                                                                    props.errors.phone && props.touched.phone && (
                                                                                                        <KpFormInputErrorMessage>
                                                                                                            {props.errors.phone}
                                                                                                        </KpFormInputErrorMessage>
                                                                                                    )
                                                                                                }
                                                                                                <input
                                                                                                    id='phone'
                                                                                                    name='phone'
                                                                                                    data-private="lipsum"
                                                                                                    aria-label='Phone Number'
                                                                                                    type='text'
                                                                                                    disabled={props.status.isSubmitting}
                                                                                                    placeholder='Phone'
                                                                                                    onChange={props.handleChange}
                                                                                                    onBlur={props.handleBlur}
                                                                                                    value={props.values.phone}>
                                                                                                </input>
                                                                                            </KpFormField>
                                                                                        </Form.Group>
                                                                                    </ContactUsFormColumn>
                                                                                    <ContactUsFormColumn mobile={16} tablet={8} computer={8}>
                                                                                        <Form.Group>
                                                                                            <KpFormField required error={props.errors.reason && props.touched.reason}>
                                                                                                <label>How can we help you today?</label>
                                                                                                {
                                                                                                    props.errors.reason && props.touched.reason && (
                                                                                                        <KpFormInputErrorMessage>
                                                                                                            {props.errors.reason}
                                                                                                        </KpFormInputErrorMessage>
                                                                                                    )
                                                                                                }
                                                                                                <KpFormDropdown fluid selection
                                                                                                    backgroundColor={theme.brand.colors.white}
                                                                                                    icon='chevron down'
                                                                                                    options={ContactReasons.map((reason) => {
                                                                                                        return {
                                                                                                            keys: reason.Reason,
                                                                                                            text: reason.Reason,
                                                                                                            value: reason.Reason
                                                                                                        }
                                                                                                    })}
                                                                                                    value={props.values.reason}
                                                                                                    onChange={(element: any) => {
                                                                                                        props.setFieldValue('reason', element.currentTarget.innerText);
                                                                                                        this.setState({
                                                                                                            selectedReason: ContactReasons.find((reason) => {
                                                                                                                if (reason.Reason === element.currentTarget.innerText){
                                                                                                                    this.setState({selectedSubtype: reason.SubType[0]})
                                                                                                                    props.setFieldValue("subtype", reason.SubType[0])
                                                                                                                    return true
                                                                                                                } else {
                                                                                                                    return false
                                                                                                                }})
                                                                                                        })
                                                                                                }} />
                                                                                            </KpFormField>
                                                                                        </Form.Group>
                                                                                    </ContactUsFormColumn>
                                                                                    <ContactUsFormColumn mobile={16} tablet={8} computer={8}>
                                                                                        <Form.Group>
                                                                                            <KpFormField error={props.errors.subtype && props.touched.subtype}>
                                                                                                <label>Topic</label>
                                                                                                {
                                                                                                    props.errors.subtype && props.touched.subtype && (
                                                                                                        <KpFormInputErrorMessage>
                                                                                                            {props.errors.subtype}
                                                                                                        </KpFormInputErrorMessage>
                                                                                                    )
                                                                                                }
                                                                                                <KpFormDropdown fluid selection
                                                                                                    backgroundColor={theme.brand.colors.white}
                                                                                                    icon='chevron down'
                                                                                                    options={this.state.selectedReason?.SubType.map((subtype) => {
                                                                                                        return {
                                                                                                            keys: subtype,
                                                                                                            text: subtype,
                                                                                                            value: subtype
                                                                                                        }
                                                                                                    })}
                                                                                                    value={this.state.selectedSubtype}
                                                                                                    onChange={(element: any) => {
                                                                                                        this.setState({selectedSubtype: this.state.selectedReason?.SubType.find((subtype) => { return subtype === element.currentTarget.innerText})})
                                                                                                        props.setFieldValue('subtype', element.currentTarget.innerText);

                                                                                                }} />
                                                                                            </KpFormField>
                                                                                        </Form.Group>
                                                                                    </ContactUsFormColumn>
                                                                                    <ContactUsFormColumn mobile={16} tablet={16} computer={16}>
                                                                                        {
                                                                                            this.state.selectedReason && this.state.selectedReason.OrderNumberRequired &&
                                                                                            <Form.Group>
                                                                                                <KpFormField required error={props.errors.orderNumber && props.touched.orderNumber}>
                                                                                                    <label>Order Number or Plate Combination</label>
                                                                                                    {
                                                                                                        props.errors.orderNumber && props.touched.orderNumber && (
                                                                                                            <KpFormInputErrorMessage>
                                                                                                                {props.errors.orderNumber}
                                                                                                            </KpFormInputErrorMessage>
                                                                                                        )
                                                                                                    }
                                                                                                    <input
                                                                                                        id='orderNumber'
                                                                                                        name='orderNumber'
                                                                                                        data-private="lipsum"
                                                                                                        type='text'
                                                                                                        aria-label='OrderNumber'
                                                                                                        disabled={props.status.isSubmitting}
                                                                                                        placeholder='Order Number or Combination'
                                                                                                        onChange={props.handleChange}
                                                                                                        onBlur={props.handleBlur}
                                                                                                        value={props.values.orderNumber}>
                                                                                                    </input>
                                                                                                </KpFormField>
                                                                                            </Form.Group>
                                                                                        }
                                                                                    </ContactUsFormColumn>
                                                                                    <ContactUsFormColumn mobile={16} tablet={16} computer={16}>
                                                                                        <Form.Group>
                                                                                            <KpFormField required error={props.errors.message && props.touched.message}>
                                                                                                <label>Message</label>
                                                                                                {
                                                                                                    props.errors.message && props.touched.message && (
                                                                                                        <KpFormInputErrorMessage>
                                                                                                            {props.errors.message}
                                                                                                        </KpFormInputErrorMessage>
                                                                                                    )
                                                                                                }
                                                                                                <textarea
                                                                                                    id='message'
                                                                                                    name='message'
                                                                                                    aria-label="Message"
                                                                                                    disabled={props.status.isSubmitting}
                                                                                                    placeholder='Message'
                                                                                                    onChange={props.handleChange}
                                                                                                    onBlur={props.handleBlur}
                                                                                                    value={props.values.message}>
                                                                                                </textarea>
                                                                                            </KpFormField>
                                                                                        </Form.Group>
                                                                                    </ContactUsFormColumn>
                                                                                    <ContactUsFormColumn mobile={16} tablet={16} computer={16}>
                                                                                        {this.state.reCaptchaError && <KpFormErrorMessageLeft>{this.state.reCaptchaError}</KpFormErrorMessageLeft>}
                                                                                        <div className="g-recaptcha-v2" data-sitekey={this.props.reCaptchaSiteKeyV2} id="g-recaptcha_contactus-v2"></div>
                                                                                    </ContactUsFormColumn>
                                                                                    <ContactUsFormColumn mobile={16} tablet={16} computer={4}>
                                                                                        <KpButton id="cub-send" type='submit' loading={props.status.isSubmitting} buttonType='primary' color={theme.brand.colors.blue}>SEND MESSAGE</KpButton>
                                                                                    </ContactUsFormColumn>
                                                                                </Grid>
                                                                            </Grid.Column>
                                                                        )
                                                                    }
                                                                    {
                                                                        props.status.isSubmitted && (
                                                                            <Grid.Column verticalAlign='middle' width={16}>
                                                                                <Paragraph1 color={theme.brand.colors.black}>
                                                                                    Thanks for your message! We will be in touch as soon as possible
                                                                                </Paragraph1>
                                                                            </Grid.Column>
                                                                        )
                                                                    }
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            )}</ReCaptcha>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </ContactUsFormContainer>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

}

export default ContactUsBlock