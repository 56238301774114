import styled from "@emotion/styled";
import theme from "@styles/theme";
import { GridColumn, GridRow } from "semantic-ui-react";
import mq from '@styles/mq'
import { Paragraph1, H3 } from "@styles/Global.styles";

export const ContactUsLabel = styled.label`
    color: #B7B7B7 !important;
    font-size: 13px !important;
    line-height: 19px;
    display: block;
    font-weight: bold;
`

export const ContactUsLabelDark = styled.label`
    color: ${theme.brand.colors.darkGrey} !important;
    font-size: 14px !important;
    line-height: 20px;
    display: block;
    font-weight: bold;
`

export const ContactUsAddress = styled(Paragraph1)`
    & > p {
        margin-bottom: 0;
    }
`

export const ContactUsLink = styled.a`
    font-weight: bold;
    ${
        mq({
            fontSize: ['20px', '20px', '24px'],
            lineHeight: ['28px', '28px', '26px']
        })
    }
    cursor: pointer;
    color: ${theme.brand.colors.black}
`

export const ContactUsHeading = styled(H3)`
    ${mq({
        textAlign: ['center','left','left']
    })}
`

export const ContactUsIconContainer = styled.div`
    margin-right: 30px;

    > svg {
        margin-top: 7px;
        ${
            mq({
                marginLeft: ['0', '0', '-10px !important']
            })
        }
    }
`

export const ContactUsColumn = styled(GridColumn)`
    ${
        mq({
            marginBottom: ['15px !important', '20px !important', '30px !important']
        })
    }
`

export const ContactUsFormContainer = styled(GridColumn)`
    ${
        mq({
            margin: ['30px 0 0 0 !important;', '30px 0 0 0 !important;', '0 0 0 0 !important;']
        })
    }
`

export const ContactUsPaddedRow = styled(GridRow)`
    ${
        mq({
            padding: ['45px 0 20px 0 !important;', '0 0 30px 0 !important;', '45px 0 20px 0 !important;']
        })
    }
`

export const ContactUsColumnSeperator = styled(GridColumn)`
    ${
        mq({
            borderRight: ['none', 'none', '4px solid #EEEEEE']
        })
    }
`

export const ContactUsFormColumn = styled(GridColumn)`
    ${
        mq({
            paddingTop: ['0px !important', '5px !important', '5px !important'],
            paddingBottom: ['20px !important', '5px !important', '5px !important']
        })
    }
`